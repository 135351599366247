import React from 'react';
import Ag from '../assets/images/Augier.png';
import Fa from '../assets/images/fa.png';
import Sl from '../assets/images/sl.png';
import dice from '../assets/images/dice.png';

const images = [
    { src: Ag, alt: 'Image 1', size: 'h-[33px]', url: 'https://augierai.com/' },
    { src: Fa, alt: 'Image 2', size: 'h-[84px]', url: 'https://storiedlife.com/' },
    { src: Sl, alt: 'Image 3', size: 'h-[71px]', url: null },  // Replace with actual URL later
    { src: dice, alt: 'Image 4', size: 'h-[70px]', url: 'http://18.222.218.159:3002' },
];

const Projects = () => {
    return (
        <div className="text-black py-8">
            <div className="flex flex-wrap justify-center gap-8 items-center">
                {images.map((image, index) => (
                    <div key={index} className="flex justify-center items-center w-[50%] sm:w-auto">
                        <a href={image.url} target="_blank" rel="noopener noreferrer">
                            <img
                                src={image.src}
                                alt={image.alt}
                                className={`object-contain ${image.size}`}
                            />
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Projects;
